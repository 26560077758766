<template>
  <div class="more">
    <div class="headMsg">
      <div class="tit">
        <div class="left">
          <p>
            在<span>{{ $route.query.name }}</span> 中搜索
          </p>
        </div>
        <div class="right">
          <p @click="goBack">
            <van-icon style="font-weight: bold" name="arrow-left" />返回上一页
          </p>
        </div>
      </div>
      <div class="search">
        <van-search
          v-model="keyword"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        />
        <!-- <van-search
          v-model="keyword"
          show-action
          placeholder="请输入搜索关键词"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search> -->
      </div>
    </div>
    <div class="filter">
      <div class="left">
        <p class="tit">所属平台</p>
        <p class="select" @click="changePlatform">全部<van-icon name="play" /></p>
      </div>
      <div class="right">
        <p class="tit">所属频道</p>
        <p class="select">全部<van-icon name="play" /></p>
      </div>
    </div>
    <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh">
      <van-list
        v-model="isUpLoading"
        :finished="upFinished"
        error-text="请求失败，点击重新加载"
        :immediate-check="false"
        finished-text="暂无数据"
        @load="onLoad"
      >
        <div class="list">
          <div
            class="item"
            @click="jumpUrl(item)"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <div class="img">
              <img :src="item.coverImage" alt="" />
              <div v-if="item.estimateCustomerCommission">
                <p>预计返{{item.estimateCustomerCommission/100}}元</p>
              </div>
            </div>
            <div class="msg">
              <p class="name">
                {{ item.title }}
              </p>
              <p class="price">{{ item.subTitle }}</p>
              <div class="boo">
                <p class="time">
                  <span>{{ item.merchantName }}</span>
                  <i></i>
                  <span>{{ item.updateTime }}</span>
                </p>
                <p class="num">
                  <van-icon size="16px" color="#999" name="eye-o" /><span>{{
                    item.displayHits
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <backTop />
    <customerService />
    <picker ref="refPicker"></picker>
  </div>
</template>

<script>
import picker from "../../component/picker"
import { keywordSearch } from "../../utils/api";
import backTop from "../../component/backTop";
import customerService from "../../component/customerService";
export default {
  data() {
    return {
      keyword: "",
      pageNo: 1,
      pageSize: 15,
      dataList: [],
      isDownLoading: false,
      isUpLoading: false,
      upFinished: false,
      shopId: "",
    };
  },
  components: { backTop, customerService,picker },
  created() {
    this.shopId = window.location.pathname.split("/")[1];
  },
  mounted() {
    this.keyword = this.$route.query.keyword;
    if (this.keyword) {
      this.keywordSearch();
    }
  },
  methods: {
    //选择平台
    changePlatform(){
      this.$refs.refPicker.showPicker = false;
    },
    // 跳转商品
    jumpUrl(item) {
      window.location.href = item.url;
    },
    //搜索
    onSearch() {
      this.pageNo = 1;
      this.isUpLoading = false;
      this.upFinished = false;
      this.keywordSearch();
    },
    onDownRefresh() {
      this.pageNo = 1;
      this.isUpLoading = true;
      this.upFinished = false; // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
      this.keywordSearch("refresh"); // 加载数据
    },
    onLoad() {
      setTimeout(() => {
        this.pageNo++;
        this.keywordSearch();
      }, 1000);
    },
    //列表
    async keywordSearch(type) {
      let fd = new FormData();
      fd.append("pageNo", this.pageNo);
      fd.append("pageSize", this.pageSize);
      fd.append("typeId", this.$route.query.typeId);
      fd.append("keyword", this.keyword);
      fd.append("shopId", this.shopId);
      if (this.pageNo === 1 && type !== "refresh") {
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
        });
      }

      await keywordSearch(fd)
        .then((res) => {
          this.$toast.clear();
          if (this.pageNo === 1) {
            this.dataList = res.data;
          } else {
            res.data.map((item) => {
              this.dataList.push(item);
            });
          }
          if (res.data == null || res.data.length === 0) {
            // 加载结束
            this.upFinished = true;
            return;
          }
          if (res.data.length < this.pageSize) {
            // 最后一页不足10条的情况
            this.upFinished = true;
          }

          this.isUpLoading = false;
        })
        .finally(() => {
          this.isDownLoading = false;
          this.isUpLoading = false;
        });
    },
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff804d;
/deep/ .van-search__content {
  background: #fcfcfc;
  border-radius: 16px;
  border: 1px solid #eeeeee;
}
/deep/ .van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  color: #999;
}
.more {
  width: 100%;
  .headMsg {
    position: fixed;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 100;
    .tit {
      width: 100%;
      padding: 10px 15px 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        p {
          font-size: 12px;
          line-height: 17px;
          color: #666;
          span {
            font-weight: bold;
            color: $primary-color;
          }
          em {
            color: $primary-color;
          }
        }
      }
      .right {
        p {
          font-size: 12px;
          line-height: 17px;
          color: #333;
          cursor: pointer;
          display: flex;
          align-items: center;
          font-weight: bold;
        }
      }
    }
  }
  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .left {
      display: flex;
      align-items: center;
      .tit {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-right: 15px;
      }
      .select {
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        line-height: 12px;
        display: flex;
        align-items: center;
        /deep/ .van-icon {
          transform: rotate(90deg);
          margin-top: -2px;
          margin-left: 3px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .tit {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-right: 15px;
      }
      .select {
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        line-height: 12px;
        display: flex;
        align-items: center;
        /deep/ .van-icon {
          transform: rotate(90deg);
          margin-top: -2px;
          margin-left: 3px;
        }
      }
    }
  }

  .list {
    margin-top: 80px;
    padding: 0 15px 10px;
    width: 100%;
    .item {
      width: 100%;
      border-bottom: 1px solid #eee;
      padding: 20px 0;
      display: flex;
      align-items: center;
      .img {
        width: 80px;
        position: relative;
        img {
          width: 80px;
          height: 80px;
        }
        div {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 99;
          width: 100%;
          height: 18px;
          background: $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          white-space: nowrap;
          color: #fff;
          font-size: 20px;
          line-height: 10px;
          transform: scale(0.5);
        }
      }
      .msg {
        width: 100%;
        padding: 8px 0;
        flex: 1;
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          font-size: 12px;
          line-height: 17px;
          font-weight: bold;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /*行数*/
          -webkit-box-orient: vertical;
          text-align: justify;
        }
        .price {
          font-size: 12px;
          line-height: 12px;
          color: #e62828;
          margin: 12px 0 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; /*行数*/
          -webkit-box-orient: vertical;
          text-align: justify;
        }
        .boo {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;
          .num {
            display: flex;
            align-items: center;
            span {
              font-size: 12px;
              color: #999;
              line-height: 12px;
              padding-left: 3px;
            }
          }
          .time {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #999;
            line-height: 12px;
            i {
              display: block;
              width: 1px;
              height: 12px;
              background: #999;
              margin: 0 6px;
            }
          }
        }
      }
    }
  }
}
</style>